function Footer() {
  return (
    <footer id="footer" role="contentinfo">
      <div className="page-container">
        <p>&#169; Navaneeth Ashok 2021</p>
      </div>
    </footer>
  );
}

export default Footer;
